import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const ThirtyDaysEndpoint = '/host_usage_30_days'
const SixtyMinsEndpoint = '/host_usage_60_minutes'
const ServiceUsageEndpoint = '/top_hits_by_service'
const HitsByAccountEndpoint = '/hits_by_account'
const PaidQueriesByCountryEndpoint = '/paid_queries_by_country'
const AuthenticationEndpoint = '/authentication'
const AccountActivityEndpoint = '/account_activity_report'
const ServerHealthCheckEndpoint = '/server_health_check'

const BadCredentialsErr = 'Your username or password was invalid.'
const BadConnectionErr = 'Something went wrong, please try again later.'

export default class DashboardAPIClient {
  constructor(requests = {}) {
    this.requests = requests
    this.apiEnvironment = API_URL

    this.authenticationEndpoint = AuthenticationEndpoint
  }

  // Batches requests.
  // Some widgets need to send multiple API requests
  getData() {
    const requests = this.requests.map(request => {
      let endpoint = ''
      let params = {}
      if (request.endpoint) {endpoint = request.endpoint}
      if (request.params) {params = request.params}

      return this.request(endpoint, params)
    })
    return axios.all(requests)
  }

  request(endpoint, params = {}) {
    return axios.get(this.apiEnvironment + endpoint, { params: params, headers: {'Authorization' : this.getAuthHeader()} })
      .then(resp => resp.data)
  }

  authenticate(username, password) {
    const authHeader = "Basic " + window.btoa(username + ':' + password)

    return axios.get(this.apiEnvironment + this.authenticationEndpoint, { params: this.params, auth: {username: username, password: password} })
      .then((data) => {
        localStorage.setItem('Authorization', authHeader)
        return data
      })
      .catch((err) => {
        this.logout()
        if (err.response && err.response.status === 401) {
          return Promise.reject(BadCredentialsErr);
        }
        return Promise.reject(BadConnectionErr)
      })
  }

  getAuthHeader() {
    return localStorage.getItem('Authorization');
  }

  // remove Authorization from local storage to log user out
  logout() {
    localStorage.removeItem('Authorization');
  }
}

export {
  ThirtyDaysEndpoint,
  SixtyMinsEndpoint,
  ServiceUsageEndpoint,
  HitsByAccountEndpoint,
  BadCredentialsErr,
  BadConnectionErr,
  PaidQueriesByCountryEndpoint,
  AccountActivityEndpoint,
  ServerHealthCheckEndpoint
}
