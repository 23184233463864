import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsBoost from 'highcharts/modules/boost'

import classNames from 'classnames'
import options from './ChartOptions'
import widgetHelper from 'utils/WidgetHelpers'
import styles from './styles.module.scss'

HighchartsBoost(Highcharts)
class HitsByAccountWidget extends Component {

  componentDidMount() {
    this.createChartAndAssignData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      if (this.chart) this.chart.destroy()
      this.createChartAndAssignData()
    }
  }

  createChartAndAssignData() {
    const { acctName, acctData } = this.props
    const chart = Highcharts.chart(acctName, options)
    this.assignDataToChart(chart, acctData)
    return chart
  }

  assignDataToChart(chart, data) {
    chart.addSeries({
      name: 'Hits',
      data: data
    }, true)
  }

  render() {
    const { totalHits, acctName } = this.props
    const accountHits = widgetHelper.numberWithCommas(totalHits)

    return (
      <div className={styles.gridContainer}>
        <div className={classNames(styles.col, styles.colMed)}>
          {acctName}
        </div>
        <div className={classNames(styles.col, styles.colLarge)}>
          <div className={styles.graphContainer}>
            <div id={acctName}></div>
          </div>
        </div>
        <div className={classNames(styles.col, styles.colSmall)}>
          {accountHits}
        </div>
      </div>
    )
  }
}

HitsByAccountWidget.propTypes = {
  acctData: PropTypes.array.isRequired,
  totalHits: PropTypes.number.isRequired,
  acctName: PropTypes.string.isRequired
}

export default HitsByAccountWidget;
