import React from 'react';
import classNames from 'classnames'

import styles from './styles.module.scss'

const SignupsByCountryFlexGrid = ({response}) => {
  return (
    <div className={styles.flexGrid}>
      {/*Left Column*/}
      <div className={classNames(styles.flexColumn, styles.columnTiny)}>
        <div className={classNames(styles.flexRow, styles.rowNormal, styles.label, styles.rotated)}>Up Down</div>
        <div className={classNames(styles.flexRow, styles.rowNormal, styles.label, styles.rotated)}>Active Signups</div>
        <div className={classNames(styles.flexRow, styles.rowNormal, styles.label, styles.rotated)}>All new Signups</div>
        <div className={classNames(styles.flexRow, styles.rowTiny)}></div> {/* empty to pad bottom third cell */}
      </div>

      {/* Country Columns */}
      { response &&
        Object.entries(response).map(([month, monthData]) => {
          return (
            <div key={month} style={{order: monthData.order }} className={classNames(styles.flexColumn, styles.columnNormal)}>
              <div style={{color: '#24843D'}} className={classNames(styles.flexRow, styles.rowNormal, styles.accountValues)}>
                {monthData.country_data.upgrades}
                <div style={{color: '#D22B2B', fontSize: '30px'}} >
                  {monthData.country_data.downgrades}
                </div>
              </div>
              <div style={{color: '#000000'}} className={classNames(styles.flexRow, styles.rowNormal, styles.accountValues)}>
                {monthData.country_data.active_signups}
              </div>
              <div style={{color: '#6b6b6b' }} className={classNames(styles.flexRow, styles.rowNormal, styles.accountValues)}>
                {monthData.country_data.total_signups}
              </div>
              <div className={classNames(styles.flexRow, styles.rowTiny, styles.label)}>
                {month}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default SignupsByCountryFlexGrid
