import React, { Component } from 'react';

// Styles
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import WidgetContainer from 'components/WidgetContainer'
import HostCount from 'components/HostCount'
import ServiceUsageWidget from 'components/ServiceUsageWidget'
import HitsByAccountContainer from 'components/HitsByAccountContainer'
import PaidQueriesByCountryContainer from 'components/PaidQueriesByCountryContainer'
import SignupsByCountryWidget from 'components/SignupsByCountryWidget'
import ServerStatus from 'components/ServerStatus'

import { Responsive, WidthProvider } from 'react-grid-layout';
import responsiveLayouts from './Layouts'

// Constants
import {
  ThirtyDaysEndpoint,
  SixtyMinsEndpoint,
  ServiceUsageEndpoint,
  HitsByAccountEndpoint,
  PaidQueriesByCountryEndpoint,
  AccountActivityEndpoint,
  ServerHealthCheckEndpoint
} from 'utils/DashboardAPIClient/DashboardAPIClient'

const ResponsiveGridLayout = WidthProvider(Responsive);

class DashboardPage extends Component {

  TARGET_WIDTH = 1920
  TARGET_HEIGHT = 1080
  TARGET_NUM_ROWS = 24

  ROW_HEIGHT = Math.floor(this.TARGET_HEIGHT / this.TARGET_NUM_ROWS)
  GRID_PADDING = [0,0]

  // Endpoint request parameters on a per widget basis
  SixtyMinsReq = [{endpoint: SixtyMinsEndpoint}]
  ThirtyDaysReq = [{endpoint: ThirtyDaysEndpoint}]
  ServiceUsageReq = [{endpoint: ServiceUsageEndpoint, params: {period: 185, interval: 5, num_services: 7}}]
  HitsByAccountReq = [{endpoint: HitsByAccountEndpoint, params: {period: 180, num_accounts: 8}}]
  PaidQueriesByCountryReq = [{endpoint: PaidQueriesByCountryEndpoint, params: {period: 364, interval: 7}}]
  ServerHealthCheckReq = [{endpoint: ServerHealthCheckEndpoint}]

  getSignupActivity() {
    return [
      {
        endpoint: AccountActivityEndpoint,
        params: { period: "current_month" }
      },
      {
        endpoint: AccountActivityEndpoint,
        params: { period: "last_month" }
      }
    ]
  }

  render() {
    this.countrySignupActivity = this.getSignupActivity()

    return (
      <ResponsiveGridLayout
        containerPadding={this.GRID_PADDING}
        rowHeight={this.ROW_HEIGHT}
        layouts={responsiveLayouts}
        breakpoints={{xl: 1600, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{xl: 14, lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
      >
        <div key="hc-60-mins">
          <WidgetContainer updatePeriodInMs={30000} requests={this.SixtyMinsReq}>
            <HostCount title="60 Minutes"/>
          </WidgetContainer>
        </div>
        <div key="hc-30-days">
          <WidgetContainer updatePeriodInMs={30000} requests={this.ThirtyDaysReq}>
            <HostCount title="30 Days"/>
          </WidgetContainer>
        </div>
        <div key="service-usage">
          <WidgetContainer updatePeriodInMs={20000} requests={this.ServiceUsageReq}>
            <ServiceUsageWidget title="Hits By Service (Per Minute)"/>
          </WidgetContainer>
        </div>
        <div key="hits-by-account">
          <WidgetContainer updatePeriodInMs={20000} requests={this.HitsByAccountReq}>
            <HitsByAccountContainer title="Hits By Account (Past 3 hours)"/>
          </WidgetContainer>
        </div>
        <div key="paid-queries-by-country">
          <WidgetContainer updatePeriodInMs={100000} requests={this.PaidQueriesByCountryReq}>
            <PaidQueriesByCountryContainer title="Paid Queries By Country"/>
          </WidgetContainer>
        </div>
        <div key="acct-activity-curr-month">
          <WidgetContainer updatePeriodInMs={300000} requests={this.countrySignupActivity}>
            <SignupsByCountryWidget country='AU'/>
          </WidgetContainer>
        </div>
        <div key="acct-activity-prev-month">
          <WidgetContainer updatePeriodInMs={300000} requests={this.countrySignupActivity}>
            <SignupsByCountryWidget country='NZ'/>
          </WidgetContainer>
        </div>
        <div key="server-status">
          <WidgetContainer updatePeriodInMs={10000} requests={this.ServerHealthCheckReq}>
            <ServerStatus title="Server Status"/>
          </WidgetContainer>
        </div>
      </ResponsiveGridLayout>
    );
  }
}
export default DashboardPage;
