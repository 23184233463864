import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsBoost from 'highcharts/modules/boost'

import Card from 'components/Card'
import WidgetError from 'components/WidgetError'

import options from './ChartOptions'
import momentHelper from 'utils/MomentHelpers'

import styles from './styles.module.scss'

HighchartsBoost(Highcharts)
class ServiceUsageWidget extends Component {

  componentDidUpdate(prevProps, prevState) {
    if (this.refs.chartContainer && !this.props.isLoading) {
      if (this.chart) this.chart.destroy()
      this.createChart()
      this.assignDataToChart()
    }
  }

  createChart() {
    this.chart = Highcharts.chart('container', options)
  }

  assignDataToChart() {
    if (!this.chart || !this.props.data || !this.props.data[0].aggr_hits_by_service) return

    // This is the hits by service data returned from the API
    let hitsByServiceData = this.props.data[0].aggr_hits_by_service

    // Sets xAxis Data
    const xAxisTimes = this.getAllTimestamps(hitsByServiceData)
    this.chart.xAxis[0].setCategories(xAxisTimes, false)

    // Gets all the unique service names
    const allServiceNames = this.getAllServiceNames(hitsByServiceData)

    // Formats data to make it easy to set yAxis data
    const yAxisData = this.getYAxisData(xAxisTimes, allServiceNames, hitsByServiceData)
    const serviceNameColors = this.getColorsOfServices(hitsByServiceData)

    // Sets yAxis Data
    Object.entries(yAxisData).forEach(([serviceName, seriesData]) => {
      this.chart.addSeries({
        name: serviceName,
        color: serviceNameColors[serviceName],
        data: seriesData
      }, false)
    })

    // Only redraw once we've added all the data.
    this.chart.redraw();
  }

  /*
    Goes through data returned from the API
    and returns a unique set of serviceNames

    Returns the serviceNames as an array
  */
  getAllServiceNames(hitsByServiceData) {
    const serviceNames = new Set()
    Object.entries(hitsByServiceData).forEach(([timestamp, serviceHits]) => {
      serviceHits.forEach((serviceHit) => {
        const serviceName = serviceHit[0]
        serviceNames.add(serviceName)
      })
    });
    return [...serviceNames]
  }

  /*
    Goes through data returned from the API
    and returns all the timestamps in a
    9:50 PM short time format
  */
  getAllTimestamps(hitsByServiceData) {
    return Object.entries(hitsByServiceData).map(([timestamp, serviceHits]) => {
      return momentHelper.toLocalShortTime(timestamp)
    });
  }

  /*
    xAxisTimes are all the times that will display on the xAxis
    allServiceNames is an array of service names
    hitsByServiceData is the data that comes back from the API

    Highcharts requires us to populate a y value for every service name
    for every x value (the times), even if one doesn't exist.
    We get around this by computing those serviceNames that don't exist at a timetime,
    and adding an entry into the highcharts data of that serviceName, timestamp, and a
    null y value.

    Stop bug displayed at: https://bit.ly/2TF89Kl
  */
  getYAxisData(xAxisTimes, allServiceNames, hitsByServiceData) {
    let serviceNamesToYAxisData = {}

    Object.entries(hitsByServiceData).forEach(([timestamp, serviceHits]) => {

      const serviceListAtTimestamp = serviceHits.map(ele => {return ele[0]})
      const serviceNamesToPad = this.arrayDiff(serviceListAtTimestamp, allServiceNames)

      serviceHits.forEach((serviceHit) => {
        const serviceName = serviceHit[0]
        if (!serviceNamesToYAxisData[serviceName]) {
            serviceNamesToYAxisData[serviceName] = [];
        }
        const hits = serviceHit[1]
        const hitsPerMinute = hits / this.props.data[0].aggregation_interval
        const timestampHitsTuple = [momentHelper.toLocalShortTime(timestamp), hitsPerMinute]
        serviceNamesToYAxisData[serviceName].push(timestampHitsTuple)
      })

      serviceNamesToPad.forEach((serviceName) => {
        if (!serviceNamesToYAxisData[serviceName]) {
            serviceNamesToYAxisData[serviceName] = [];
        }
        const timestampHitsTuple = [momentHelper.toLocalShortTime(timestamp), null]
        serviceNamesToYAxisData[serviceName].push(timestampHitsTuple)
      })
    })
    return serviceNamesToYAxisData
  }

  /*
    Returns a map of service names to color.

    Used to consistently assign the same color to
    a service (assuming the API) consistently
    returns the same color for a serviceName
  */
  getColorsOfServices(hitsByServiceData) {
    let serviceNameToColor = {}
    Object.entries(hitsByServiceData).forEach(([timestamp, serviceHits]) => {
      serviceHits.forEach((serviceHit) => {
        const serviceName = serviceHit[0]
        const color = serviceHit[2]
        if (!serviceNameToColor[serviceName]) {
            serviceNameToColor[serviceName] = color;
        }
      })
    });
    return serviceNameToColor
  }

  /*
    Returns the difference between two arrays
    src: https://stackoverflow.com/a/52430020
  */
  arrayDiff(a, b) {
    return [
        ...a.filter(x => !b.includes(x)),
        ...b.filter(x => !a.includes(x))
    ];
  }

  render() {
    const { isLoading, title, connectionError } = this.props
    return (
      <Card title={title} isLoading={isLoading}>
        <div className={styles.graphContainer}>
          { this.props.connectionError ? <WidgetError errTxt={connectionError}/> : this.renderGraph() }
        </div>
      </Card>
    )
  }

  renderGraph() {
    return <div id="container" ref="chartContainer"/>
  }
}

ServiceUsageWidget.defaultProps = {
  isLoading: true,
  connectionError: null,
  data: [],
}

ServiceUsageWidget.propTypes = {
  title: PropTypes.string.isRequired
}

export default ServiceUsageWidget;
