import React, { Fragment } from 'react';
import classNames from 'classnames'

import styles from './styles.module.scss'

const FormGroup = ({ type, name, value, placeholder, handleChange, submitted, errorMessage }) => {
  return (
    <Fragment>
      <div className={classNames(styles.formGroup, (submitted && !value ? styles.hasError : null))}>
        <input type={type} className={styles.formControl} name={name} value={value} placeholder={placeholder} onChange={handleChange} />
        {submitted && !value && <div className={styles.helpBlock}>{errorMessage}</div>}
      </div>
    </Fragment>
  )
}

export default FormGroup;
