import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import Card from 'components/Card'
import WidgetError from 'components/WidgetError'
import PaidQueriesByCountryWidget from 'components/PaidQueriesByCountryWidget'

import widgetHelper from 'utils/WidgetHelpers'
import momentHelper from 'utils/MomentHelpers'

import styles from './styles.module.scss'

class PaidQueriesByCountryContainer extends Component {

  componentDidMount() {
    this.mostHitsAllCountries = 0
  }

  formatCountryData(data) {
    data = data[0].country_data
    return Object.entries(data).map((data) => {
      const countryName = data[0]
      const countryData = data[1]

      this.updateMostHitsAllCountries(countryData)

      // Add comma's every thousandth on the number
      countryData.hits_today = widgetHelper.numberWithCommas(countryData.hits_today)

      const hits_by_day_data = countryData.hits_by_day
      const interval = this.props.data[0].interval_in_days

      countryData.hits_by_day = []
      countryData.hits_by_day = Object.keys(hits_by_day_data).map((timestamp) => {
        const timestampRange = timestamp + " - " + momentHelper.addDaysToDate(timestamp, interval - 1)
        return [timestampRange, hits_by_day_data[timestamp]];
      });

      return {name: countryName.toUpperCase(), data: countryData}
    })
  }

  updateMostHitsAllCountries(countryData) {
    Object.entries(countryData.hits_by_day).forEach(dateToHits => {
      const hits = dateToHits[1]
      this.mostHitsAllCountries = hits > this.mostHitsAllCountries ? hits : this.mostHitsAllCountries
    })
  }

  render() {
    const { title, isLoading, connectionError } = this.props
    return (
      <Card title={title} isLoading={isLoading}>
        <div className={styles.widgetContainer}>
          { connectionError ? <WidgetError errTxt={connectionError}/> : this.renderWidget()}
        </div>
      </Card>
    )
  }

  renderWidget() {
    const { data } = this.props
    if (!data) return

    this.countryData = this.formatCountryData(data)
    return (
      <Fragment>
        {this.countryData.map(country => (
          <PaidQueriesByCountryWidget key={country.name} countryData={country} mostHits={this.mostHitsAllCountries}/>
        ))}
      </Fragment>
    )
  }
}
PaidQueriesByCountryContainer.propTypes = {
  title: PropTypes.string.isRequired
}

export default PaidQueriesByCountryContainer;
