import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Card from 'components/Card'
import WidgetError from 'components/WidgetError'
import SignupsByCountryFlexGrid from 'components/SignupsByCountryFlexGrid'

import momentHelper from 'utils/MomentHelpers'

import styles from './styles.module.scss'

class SignupsByCountryWidget extends Component {

  abbrCountryName = this.props.country.toUpperCase()
  longCountryName = this.getFullNameByAbbr(this.abbrCountryName)

  formatData(allResponses) {
    const monthsToCountryData = {}

    allResponses.forEach(response => {
      const month = this.getMonthFrom(response)

      // response.data is formatted as such: [[nz, {:total_signups=>3, :active_signups=>1, :downgrades=>1, :upgrades=>2}], [au, {:total_signups=>3, :active_signups=>1, :downgrades=>1, :upgrades=>2}]]
      response.data.forEach(country => {
        let countryDataObj = this.convertToObject(country)
        if (countryDataObj.abbr_name === this.abbrCountryName) {
          this.appendOrderBy(month, countryDataObj)

          monthsToCountryData[month] = countryDataObj
        }
      })
    })
    console.log()
    return monthsToCountryData
  }

  convertToObject(countryArr) {
    let countryObj = {}
    const countryName = countryArr[0].toUpperCase()
    const countryData = countryArr[1]
    countryObj['abbr_name'] = countryName
    countryObj['country_data'] = countryData
    return countryObj
  }

  appendOrderBy(month, countryDataObj) {
    countryDataObj['order'] = momentHelper.getMonthOrdinalByName(month)
  }

  getMonthFrom(response) {
    return momentHelper.getMonthNameFromDate(response.start_interval)
  }

  getColorBy(countryName) {
    switch (countryName) {
      case 'AU':
        return '#00843D';
      case 'NZ':
        return '#000000';
      default:
        return '#D3D3D3'
    }
  }

  getFullNameByAbbr(countryName) {
    switch (countryName) {
      case 'AU':
        return 'Australia';
      case 'NZ':
        return 'New Zealand';
      default:
        return 'Unknown'
    }
  }

  render() {
    const { connectionError, isLoading } = this.props
    return (
      <Card isLoading={isLoading}>
        <div className={styles.widgetContainer}>
          { connectionError ? <WidgetError errTxt={connectionError}/> : this.renderWidget() }
        </div>
      </Card>
    );
  }

  renderWidget() {
    let { data } = this.props
    data = data ? this.formatData(data) : null

    if (!data) return null;
    return (
      <Fragment>
        <div className={styles.countryTitle}>
          {this.longCountryName}
        </div>

        <SignupsByCountryFlexGrid response={data}/>
      </Fragment>
    )
  }
}
SignupsByCountryWidget.propTypes = {
  country: PropTypes.string.isRequired,
}

export default SignupsByCountryWidget;
