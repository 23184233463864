import React, { Component, Fragment } from 'react';

import styles from './styles.module.scss'

import WidgetLoader from 'components/WidgetLoader'

class Card extends Component {
  render() {
    return (
      <Fragment>
        <div className={styles.card}>
          {!this.props.title ? null : <h2 className={styles.cardTitle}>{this.props.title}</h2>}
          {this.props.children}
        </div>
        {this.props.isLoading && <WidgetLoader/>}
      </Fragment>
    );
  }
}

export default Card;
