import Loader from 'react-loader-spinner'
import React, { Component } from 'react'

import styles from './styles.module.scss'

class WidgetLoader extends Component {

  render() {
    return (
      <div className={styles.loaderContainer}>
        <Loader type="Ball-Triangle" color={this.props.color || "black"} height={this.props.height || 20} width={this.props.width || 20} />
      </div>
    );
  }
}

export default WidgetLoader;
