export default {
  chart: {
    height: 42,
    margin: [2, 0, 2, 0],
    backgroundColor: null,
    borderWidth: 0,
    skipClone: true,
    type: 'area',
    reflow: true,
    events: {
      load: function () {
        var chart = this;
        if (chart) {
          chart.reflow();
        }
      }
    }
  },
  title: {text: ''},
  subtitle: {text: ''},
  credits: false,
  xAxis: {
    labels: {
        enabled: false
    },
    title: {
        text: null
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: []
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
        enabled: false
    },
    title: {
        text: null
    },
    tickPositions: [0]
  },
  legend: {
    enabled: false
  },
  tooltip: {
    hideDelay: 0,
    outside: true,
    shared: true
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
            lineWidth: 1
        }
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2
          }
        }
      },
      fillOpacity: 0.25
    },
    column: {
      negativeColor: '#910000',
      borderColor: 'silver'
    }
  }
};
