import React, { Component } from 'react'
import PropTypes from 'prop-types';

import styles from './styles.module.scss'

class WidgetError extends Component {

  render() {
    return (
      <div className={styles.errTxt}>{this.props.errTxt}</div>
    );
  }
}
WidgetError.propTypes = {
  errTxt: PropTypes.string.isRequired
}

export default WidgetError;
