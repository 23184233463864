import React, { Fragment } from 'react';

import ButtonLoader from 'components/ButtonLoader'

import styles from './styles.module.scss'

const LoginButton = ({ loading, error }) => {
  return (
    <Fragment>
      <button className={styles.loginBtn}>
        {loading ? <ButtonLoader height={25} width={25} color="white"/> : 'Login'}
      </button>
      {error && <div className={styles.errorAlert}>{error}</div>}
    </Fragment>
  )
}
export default LoginButton;
