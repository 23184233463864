import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import Card from 'components/Card'
import WidgetError from 'components/WidgetError'
import HitsByAccountWidget from 'components/HitsByAccountWidget'

import momentHelper from 'utils/MomentHelpers'

import styles from './styles.module.scss'

class HitsByAccountContainer extends Component {

  /*
    We get two sets of data back from the API that we
    want to merge together. We do this to make rendering
    the data nice and clean.
  */
  formatData(data) {
    const topAccountNamesToTotalHits = data[0].top_account_hits
    const accountHitsTimestamps = data[0].account_hits_timestamps
    const graphPeriod = data[0].period

    const timeIntervalsInMins = momentHelper.getMinuteIntervalsFrom(graphPeriod)

    const mergedData = []

    topAccountNamesToTotalHits.forEach(acctToHits => {
      const acctName = acctToHits[0]
      const acctTotalHits = acctToHits[1]

      // Where in the other data to be merged is this accountNames data?
      const idxInSparkData = Object.keys(accountHitsTimestamps).findIndex((sparkName) => sparkName === acctName)
      if (idxInSparkData === -1) return

      let formattedObj = {
        name: acctName,
        totalHits: acctTotalHits,
        data: []
      }

      let sparklineData = Object.entries(accountHitsTimestamps)[idxInSparkData][1]
      formattedObj.data = this.formatSparklineData(sparklineData, timeIntervalsInMins)

      mergedData.push(formattedObj)
    })
    return mergedData
  }

  formatSparklineData(accountData, timeIntervalsInMins) {
    const accountTimestamps = accountData.map(timestampToHits => {return momentHelper.toLocalShortTime(timestampToHits[0])})
    const paddedTimeIntervals = timeIntervalsInMins.map(time => {
      // Does this account have this time in its data? If not, pad it.
      const timeIdx = accountTimestamps.indexOf(time)
      if (timeIdx === -1) return [time, 0]
      else {
        const data = accountData[timeIdx]
        const shortTimestamp = momentHelper.toLocalShortTime(data[0])
        const hits = data[1]
        return [
          shortTimestamp,
          hits
        ]
      }
    })
    return paddedTimeIntervals
  }

  render() {
    const { title, isLoading, connectionError } = this.props
    return (
      <Card title={title} isLoading={isLoading}>
        <div className={styles.widgetContainer}>
          { connectionError ? <WidgetError errTxt={connectionError}/> : this.renderWidget() }
        </div>
      </Card>
    )
  }

  renderWidget() {
    const { data } = this.props
    if (!data) return
    this.acctData = this.formatData(data)
    return (
      <Fragment>
        {this.acctData.map(acct => (
          <HitsByAccountWidget key={acct.name} acctName={acct.name} acctData={acct.data} totalHits={acct.totalHits}/>
        ))}
      </Fragment>
    )
  }
}
HitsByAccountContainer.propTypes = {
  title: PropTypes.string.isRequired
}

export default HitsByAccountContainer;
