import moment from 'moment'
import range from 'lodash.range';

/*
  Adds : in timezone of timestamp. Goes from +-0000 to +-00:00
  Also adds 'T' inbetween date and time.
  Have to do this to conform to ECMA-262 and fix bug with safari invalid dates
  https://stackoverflow.com/a/50699228
*/
function convertToECMA262(timestamp) {
  const tsSplit = timestamp.split(' ')
  const fixedDateTime = tsSplit[0] + 'T' + tsSplit[1]
  // Add : in middle of timezone
  const timeZone = tsSplit[2]
  const fixedTimezone = timeZone.slice(0, 3) + ':' + timeZone.slice(3, 5)
  // Combine
  const fixedTimestamp = fixedDateTime.concat(fixedTimezone)
  return fixedTimestamp
}

// Converts from UTC to NZST and formats in '8:30 PM' format
function toLocalShortTime(timestamp) {
  if (new Date(timestamp) === 'Invalid Date') {
    timestamp = convertToECMA262(timestamp)
  }
  return moment(new Date(timestamp)).format('LT');
}

function addDaysToDate(date, interval) {
  return moment(date, 'YYYY-MM-DD').add(interval, 'days').format('YYYY-MM-DD')
}

function getStartAndEndOfMonth(date) {
  return {
    start: date.startOf('month').format("YYYY-MM-DD"),
    end: date.endOf('month').format("YYYY-MM-DD")
  }
}

function getMonthNameFromDate(date) {
  return moment(date).format('MMMM')
}

function getTimeAgo(timestamp) {
  const timeTypes = ['day', 'hour', 'minute']
  let bestTimeAgoString = getPeriodAgoString(0, 'minute')

  timeTypes.some(timeType => {
    const howLongAgo = moment().diff(moment(timestamp), timeType)
    if (howLongAgo > 0) {
      bestTimeAgoString = getPeriodAgoString(howLongAgo, timeType)
      return true
    }
    return false
  })

  return bestTimeAgoString
}

function getPeriodAgoString(value, type_string) {
  if (value === 1) return `${value} ${type_string} ago`
  if (value === 0 || value >= 2) return `${value} ${type_string}s ago`
}

// Returns an array of times in NZST, formatted in '8:30 PM' format.
// These times are from the current time to @param period minutes ago.
function getMinuteIntervalsFrom(periodInMins) {
  const intervals = range(periodInMins, 0) // returns [180, 179 ... 0]
  const currTime = moment()
  const minuteIntervalArr = intervals.map(interval => {
    // moment() is mutable. Stops this.
    return currTime.clone().subtract(interval, 'minutes').format('LT')
  })
  return minuteIntervalArr
}

function getMonthOrdinalByName(monthName) {
  return moment().month(monthName).format("M")
}

export default {
  toLocalShortTime,
  addDaysToDate,
  getStartAndEndOfMonth,
  getMonthNameFromDate,
  getMinuteIntervalsFrom,
  getMonthOrdinalByName,
  getTimeAgo
}
