import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsBoost from 'highcharts/modules/boost'

import classNames from 'classnames'
import options from './ChartOptions'
import styles from './styles.module.scss'

HighchartsBoost(Highcharts)
class PaidQueriesByCountryWidget extends Component {

  componentDidMount() {
    const countryName = this.props.countryData.name
    this.chart = Highcharts.chart(countryName, options)
    this.assignDataToChart(countryName)
  }

  componentWillUnmount() {
    if (this.chart) this.chart.destroy()
  }

  assignDataToChart(countryNameKey) {
    const countryData = this.props.countryData.data

    this.chart.addSeries({
      name: 'Hits',
      data: countryData.hits_by_day
    }, true)

    this.chart.yAxis[0].options.max = this.props.mostHits
  }

  render() {
    const { countryData } = this.props
    return (
      <div className={styles.gridContainer}>
        <div className={classNames(styles.col, styles.colSmall)}>
          {countryData.name}
        </div>
        <div className={classNames(styles.col, styles.colLarge)}>
          <div className={styles.graphContainer}>
            <div id={countryData.name}></div>
          </div>
        </div>
        <div className={classNames(styles.col, styles.colMed)}>
          {countryData.data.hits_today}
        </div>
      </div>
    )
  }
}

PaidQueriesByCountryWidget.propTypes = {
  countryData: PropTypes.object.isRequired,
  mostHits: PropTypes.number.isRequired
}

export default PaidQueriesByCountryWidget;
