import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const HostCountGrid = ({data}) => {
  return (
    <div className={styles.mainContainer}>
      <div className={classNames(styles.col, styles.leftCol)}>
          <div className={styles.topRow}>
            <div className={styles.currentHostCount}>
              {data.total_number_hosts}
            </div>
          </div>
          <div className={classNames(styles.bottomRow, styles.updatedAt)}>
            Updated at {data.time_queried}
          </div>
      </div>
      <div className={classNames(styles.col, styles.rightCol)}>
        <div className={styles.topRow}>
          <div className={styles.maxHostCount}>
            {data.max_historic_num_hosts.value}
          </div>
        </div>
        <div className={styles.bottomRow}>
          Recorded {data.max_historic_num_hosts.last_updated}
        </div>
      </div>
    </div>
  )
}

export default HostCountGrid
