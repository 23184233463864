import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import './App.scss';

import PrivateRoute from 'components/PrivateRoute';
import DashboardPage from 'components/DashboardPage'
import LoginPage from 'components/LoginPage'

class App extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={DashboardPage} />
          <Route path="/login" component={LoginPage} />
        </Switch>
      </Router>
    );
  }
}
export default App;
