import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import Card from 'components/Card'
import WidgetError from 'components/WidgetError'

import styles from './styles.module.scss'

class ServerStatus extends Component {

  render() {
    const { title, isLoading, connectionError } = this.props
    return (
      <Card title={title} isLoading={isLoading}>
        <div className={styles.widgetContainer}>
          { connectionError ? <WidgetError errTxt={connectionError}/> : this.renderWidget()}
        </div>
      </Card>
    )
  }

  renderWidget() {
    const { data } = this.props
    if (!data) return

    return (
      <Fragment>
        <div className={styles.rowGroup}>
          <span className={styles[data[0].primary && data[0].primary.toLowerCase()]}>Primary</span>
          <span className={styles[data[0].secondary && data[0].secondary.toLowerCase()]}>Secondary</span>
        </div>
        <div className={styles.rowGroup}>
          <span className={styles[data[0].tertiary && data[0].tertiary.toLowerCase()]}>Tertiary</span>
          <span className={styles[data[0].quaternary && data[0].quaternary.toLowerCase()]}>Quaternary</span>
        </div>
        <div className={styles.rowGroup}>
          <span className={styles[data[0].static1 && data[0].static1.toLowerCase()]}>Static 1</span>
          <span className={styles[data[0].static2 && data[0].static2.toLowerCase()]}>Static 2</span>
        </div>
        <div className={styles.rowGroup}>
          <span className={styles[data[0].au_pv_api && data[0].au_pv_api.toLowerCase()]}>AU PV API</span>
          <span className={styles[data[0].nz_pv_api && data[0].nz_pv_api.toLowerCase()]}>NZ PV API</span>
        </div>
      </Fragment>
    )
  }

}

ServerStatus.propTypes = {
  title: PropTypes.string.isRequired
}

export default ServerStatus;
