const isDraggable = false
const isResizable = false

export default {
  xl: [
    {i: 'hc-60-mins', x: 6, y: 9, w: 3, h: 4.5, isDraggable, isResizable},
    {i: 'hc-30-days', x: 9, y: 9, w: 3, h: 4.5, isDraggable, isResizable},
    {i: 'acct-activity-prev-month', x: 6, y: 14, w: 3, h: 6, isDraggable, isResizable},
    {i: 'acct-activity-curr-month', x: 9, y: 19, w: 3, h: 6, isDraggable, isResizable},
    {i: 'service-usage', x: 0, y: 0, w: 6, h: 9, isDraggable, isResizable},
    {i: 'hits-by-account', x: 0, y: 9, w: 6, h: 10, isDraggable, isResizable},
    {i: 'paid-queries-by-country', x: 6, y: 0, w: 6, h: 4.5, isDraggable, isResizable},
    {i: 'server-status', x: 6, y: 20, w: 3, h: 4, isDraggable, isResizable},
  ],
  lg: [
    {i: 'hc-60-mins', x: 6, y: 5, w: 2.8, h: 5, isDraggable, isResizable},
    {i: 'hc-30-days', x: 6, y: 10, w: 2.8, h: 5, isDraggable, isResizable},
    {i: 'acct-activity-prev-month', x: 8.8, y: 14, w: 3.2, h: 6, isDraggable, isResizable},
    {i: 'acct-activity-curr-month', x: 8.8, y: 19, w: 3.2, h: 6, isDraggable, isResizable},
    {i: 'service-usage', x: 0, y: 0, w: 6, h: 9, isDraggable, isResizable},
    {i: 'hits-by-account', x: 0, y: 9, w: 6, h: 10, isDraggable, isResizable},
    {i: 'paid-queries-by-country', x: 6, y: 0, w: 6, h: 5, isDraggable, isResizable},
    {i: 'server-status', x: 6, y: 15, w: 2.8, h: 4, isDraggable, isResizable},
  ],
  md: [
    {i: 'hc-60-mins', x: 0, y: 18, w: 3, h: 5, isDraggable, isResizable},
    {i: 'hc-30-days', x: 0, y: 23, w: 3, h: 5, isDraggable, isResizable},
    {i: 'acct-activity-curr-month', x: 6.5, y: 23, w: 3.5, h: 6, isDraggable, isResizable},
    {i: 'acct-activity-prev-month', x: 3, y: 23, w: 3.5, h: 6, isDraggable, isResizable},
    {i: 'service-usage', x: 0, y: 0, w: 10, h: 9, isDraggable, isResizable},
    {i: 'hits-by-account', x: 0, y: 0, w: 10, h: 9, isDraggable, isResizable},
    {i: 'paid-queries-by-country', x: 5, y: 18, w: 7, h: 5, isDraggable, isResizable},
    {i: 'server-status', x: 0, y: 28, w: 3, h: 5, isDraggable, isResizable},
  ],
  sm: [
    {i: 'hc-60-mins', x: 0, y: 30, w: 2.5, h: 4.25, isDraggable, isResizable},
    {i: 'hc-30-days', x: 2.5, y: 30, w: 2.5, h: 4.25, isDraggable, isResizable},
    {i: 'acct-activity-curr-month', x: 2.5, y: 25, w: 2.5, h: 6, isDraggable, isResizable},
    {i: 'acct-activity-prev-month', x: 0, y: 25, w: 2.5, h: 6, isDraggable, isResizable},
    {i: 'service-usage', x: 0, y: 0, w: 6, h: 9, isDraggable, isResizable},
    {i: 'hits-by-account', x: 0, y: 9, w: 6, h: 10, isDraggable, isResizable},
    {i: 'paid-queries-by-country', x: 0, y: 19, w: 6, h: 5, isDraggable, isResizable},
    {i: 'server-status', x: 0, y: 34.25, w: 2.5, h: 4, isDraggable, isResizable},
  ],
  xs: [
    {i: 'hc-60-mins', x: 0, y: 25, w: 4, h: 4.25, isDraggable, isResizable},
    {i: 'hc-30-days', x: 4.25, y: 29.25, w: 4, h: 4.25, isDraggable, isResizable},
    {i: 'acct-activity-curr-month', x: 0, y: 38.50, w: 4, h: 6, isDraggable, isResizable},
    {i: 'acct-activity-prev-month', x: 0, y: 33.50, w: 4, h: 6, isDraggable, isResizable},
    {i: 'service-usage', x: 0, y: 0, w: 4, h: 9, isDraggable, isResizable},
    {i: 'hits-by-account', x: 0, y: 9, w: 4, h: 10, isDraggable, isResizable},
    {i: 'paid-queries-by-country', x: 0, y: 19, w: 6, h: 5, isDraggable, isResizable},
    {i: 'server-status', x: 0, y: 44.5, w: 4, h: 4, isDraggable, isResizable},
  ],
  xxs: [
    {i: 'hc-60-mins', x: 0, y: 25, w: 2, h: 4.25, isDraggable, isResizable},
    {i: 'hc-30-days', x: 0, y: 29.25, w: 2, h: 4.25, isDraggable, isResizable},
    {i: 'acct-activity-curr-month', x: 0, y: 39.25, w: 2.3, h: 6, isDraggable, isResizable},
    {i: 'acct-activity-prev-month', x: 0, y: 34.25, w: 2.3, h: 6, isDraggable, isResizable},
    {i: 'service-usage', x: 0, y: 0, w: 2, h: 9, isDraggable, isResizable},
    {i: 'hits-by-account', x: 0, y: 9, w: 2, h: 10, isDraggable, isResizable},
    {i: 'paid-queries-by-country', x: 0, y: 19, w: 6, h: 5, isDraggable, isResizable},
    {i: 'server-status', x: 0, y: 45.25, w: 2.3, h: 4, isDraggable, isResizable},
  ]
}
