import React, { Fragment } from 'react'

import Card from 'components/Card'
import WidgetError from 'components/WidgetError'
import HostCountGrid from 'components/HostCountGrid'

import momentHelper from 'utils/MomentHelpers'
import widgetHelper from 'utils/WidgetHelpers'

import styles from './styles.module.scss'

const HostCount = ({isLoading, connectionError, data, title}) => {
  return (
    <Card isLoading={isLoading} title={title}>
      <div className={styles.countContainer}>
        { renderHostCount()}
      </div>
    </Card>
  )

  function formatData(data) {
    const hostData = JSON.parse(JSON.stringify(data))[0]

    hostData.total_number_hosts = widgetHelper.numberWithCommas(hostData.total_number_hosts)
    hostData.time_queried = momentHelper.toLocalShortTime(hostData.time_queried)

    hostData.max_historic_num_hosts.value = widgetHelper.numberWithCommas(hostData.max_historic_num_hosts.value)
    hostData.max_historic_num_hosts.last_updated = momentHelper.getTimeAgo(hostData.max_historic_num_hosts.last_updated)
    return hostData
  }

  function renderHostCount() {
    if (connectionError) {
      return (
        <WidgetError errTxt={connectionError}/>
      )
    }
    else {
      if (!data) return

      let formattedData = formatData(data)
      return (
        <Fragment>
          <HostCountGrid data={formattedData}/>
          <div className={styles.widgetTitle}>Server/Website Count</div>
        </Fragment>
      )
    }
  }
}

export default HostCount
