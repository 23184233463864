import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DashboardAPIClient from 'utils/DashboardAPIClient/DashboardAPIClient'
import styles from './styles.module.scss'

class WidgetContainer extends Component {

  error = 'Failed To Retrieve Data'

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      data: null,
      connectionError: null
    }
  }

  componentDidMount() {
    this.getData()
    this.updateInterval = setInterval(() => {
        this.getData()
    }, this.props.updatePeriodInMs)
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval)
  }

  getData() {
    this.setState({isLoading: true});
    return new DashboardAPIClient(this.props.requests).getData()
    .then(data => {
      let errors = false
      data.forEach(response => {
        // If we get any error codes back from any requests
        if (response.error || response.success === false) {
          errors = true
          this.setState({
            isLoading: false,
            connectionError: this.error,
            data: null
          })
        }
      })
      if (!errors) {
        this.setState({
          isLoading: false,
          data: data,
          connectionError: null,
        })
      }
    })
    .catch(err => {
      this.setState({
        isLoading: false,
        data: null,
        connectionError: this.error
      });
    })
  }

  render() {
    // console.log('widgetcont state', this.state)
    return (
      <div className={styles.widgetContainer}>
        {React.Children.map(this.props.children, child => {
          return React.cloneElement(child, {
              ...this.state
          });
        })}
      </div>
    )
  }
}

WidgetContainer.propTypes = {
  requests: PropTypes.array.isRequired
}

export default WidgetContainer
