import React, { Component } from 'react';
import DashboardAPIClient from 'utils/DashboardAPIClient/DashboardAPIClient'

import Card from 'components/Card'
import FormGroup from 'components/FormGroup'
import LoginButton from 'components/LoginButton'

import styles from './styles.module.scss'

class LoginPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
        username: '',
        password: '',
        submitted: false,
        loading: false,
        error: ''
    };

    new DashboardAPIClient().logout()
  }

  handleSubmit = (e) => {
      e.preventDefault();

      this.setState({ submitted: true });
      const { username, password } = this.state;

      // stop here if form is invalid
      if (!(username && password)) {
          return;
      }

      this.setState({ loading: true });

      new DashboardAPIClient().authenticate(username, password)
        .then((data) => {
            this.setState({ error: null, loading: false });

            // Go to DashboardPage
            this.props.history.push('/');
        })
        .catch((err) => {
          this.setState({ error: err, loading: false });
        })
  }

  handleChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
  }

  render() {
    const { username, password, submitted, loading, error } = this.state;
    return (
      <div className={styles.loginPageContainer}>
        <div className={styles.loginFormContainer}>
          <div className={styles.addressFinderLogo}></div>
          <Card title={''}>
            <form name="form" className={styles.form} onSubmit={this.handleSubmit}>
              <FormGroup type='text' name='username' value={username} placeholder='Username' handleChange={this.handleChange} submitted={submitted} errorMessage='Username is required'/>
              <FormGroup type='password' name='password' value={password} placeholder='Password' handleChange={this.handleChange} submitted={submitted} errorMessage='Password is required'/>
              <LoginButton loading={loading} error={error}/>
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

export default LoginPage;
