export default {
  chart: {
    type: 'area',
    reflow: true,
    events: {
      redraw: function () {
        var chart = this;
        if (chart) {
          setTimeout(function () {
            chart.reflow();
          }, 0)
        }
      }
    }
  },
  title: {text: ''},
  subtitle: {text: ''},
  credits: false,
  legend: {align: 'center', verticalAlign: 'bottom', layout: 'horizontal'},
  xAxis: {
      title: {enabled: false},
      tickmarkPlacement: 'on',
      type: 'datetime',
      showFirstLabel: true,
      showLastLabel: true,
      labels: {
        step: 12,
      }
  },
  yAxis: {
      title: {text: ''},
  },
  tooltip: {enabled: true, valueSuffix: ' hits'},
  plotOptions: {
      area: {
          stacking: 'normal', lineColor: '#666666', lineWidth: 1,
      },
      series: {
        connectNulls: true,
        marker: {enabled: false}
      }
  },
}
